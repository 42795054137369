import React from 'react';
import { Container } from 'emotion-flex';
import { SEO } from '../../components';
import { withLayout } from '../../components/Layout';

const AnmeldungPage = () => (
  <Container>
    <SEO title="Anmeldung Bestätigt" />
    <div
      css={{
        margin: '48px auto',
        maxWidth: 960,
        textAlign: 'center',
      }}
    >
      <h2>Vielen Dank! Lade dir jetzt hier das E-Book runter.</h2>
      <a href="//assets.ctfassets.net/pmt5vquvp72j/26tvkFSYKoSOqWeFGmTOxj/1e42f168b750ad5ed70443abf70a4b64/Die-Low-FODMAP-Diaet-Schritt-Fuer-Schritt-2020-Sept-Update.pdf">
        <img
          src="//images.ctfassets.net/pmt5vquvp72j/2ZWv6XoVHbwKlB5E8kNLPL/446b8714fc88239a1d5a7b4caac8541d/low-fodmap-diaet.jpg"
          alt="Low-Fodmap"
        />
      </a>
      <p>
        Du erhältst zeitnah von uns eine E-Mail. Wenn du deine E-Mail-Adresse
        bestätigst, erlaubst du uns mit dir per E-Mail in Kontakt zu bleiben. So
        können wir dir von Zeit zu Zeit Informationen zur Verdauungsgesundheit
        senden und dich über Neuigkeiten zur Behandlung eines Reizdarms
        informieren – das Ganze natürlich kostenlos.
      </p>
      <p>
        Ein Reizdarm bedeutet für viele Betroffene eine immense Einschränkung
        der Lebensqualität. Wir geben unser Bestes, um dir neue
        Therapiemöglichkeiten aufzuzeigen.
      </p>
      <p>Viel Erfolg und gute Besserung wünscht dir dein Team von Cara Care!</p>
    </div>
  </Container>
);

export default withLayout(AnmeldungPage, { isNewLayout: true });
